import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    AccountRecoveryActionService,
    AccountRecoveryDataService,
    AuthenticationActionService,
    AuthenticationDataService,
    AuthenticationStatusEnum,
    ElementValidator,
    ErrorInterface,
    LoginRequestInterface,
    NewModalRef,
    PersonalInformationInterface,
    UpdateTemporaryPasswordInterface,
} from 'outshared-lib';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppRoutesEnum } from '@app-de/routes';
import { equalPassword } from '@app-de/shared/validators/equal-password.validator';

@Component({
    selector: 'ins-temporary-password-modal',
    templateUrl: './temporary-password.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TemporaryPasswordModal implements OnInit, OnDestroy {
    public submitting: boolean = false;
    public errors: ErrorInterface[] = [];
    public notifications: ErrorInterface[] = [];
    public form: UntypedFormGroup;

    private subscriptions!: Subscription;

    public get email(): AbstractControl {
        return this.form.get('email');
    }

    public get temporaryPassword(): AbstractControl {
        return this.form.get('temporaryPassword');
    }

    public get newPassword(): AbstractControl {
        return this.form.get('newPassword');
    }

    public get repeatPassword(): AbstractControl {
        return this.form.get('repeatPassword');
    }

    constructor(
        private router: Router,
        private accountRecoveryActionService: AccountRecoveryActionService,
        private accountRecoveryDataService: AccountRecoveryDataService,
        private authenticationActionService: AuthenticationActionService,
        private authenticationDataService: AuthenticationDataService,
        private modalRef: NewModalRef<TemporaryPasswordModal>,
        private elementValidator: ElementValidator,
    ) {}

    public ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.initializeForm();
        this.checkAuthenticationStatus();
        this.loadPersonalInformation();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        const request: UpdateTemporaryPasswordInterface = {
            email: this.email.value,
            temporaryPassword: this.temporaryPassword.value?.trim(),
            newPassword: this.newPassword.value,
        };

        this.errors = [];
        this.submitting = true;
        this.accountRecoveryActionService.updateTemporaryPassword$(request).subscribe(
            () => this.autoLogin(),
            (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.submitting = false;
            },
        );
    }

    private initializeForm(): void {
        this.form = new UntypedFormGroup(
            {
                email: new UntypedFormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
                temporaryPassword: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
                newPassword: new UntypedFormControl('', {
                    validators: [Validators.required],
                    asyncValidators: [
                        this.elementValidator.validate('password', 'VERZ.PROD.VSLG.NREL.PROD.AUTO', 'relation_login'),
                    ],
                }),
                repeatPassword: new UntypedFormControl('', [Validators.required]),
            },
            {
                validators: equalPassword('newPassword', 'repeatPassword'),
            },
        );
    }

    private loadPersonalInformation(): void {
        const personalInformationSubscription = this.accountRecoveryDataService
            .getPersonalInformation$()
            .pipe(take(1))
            .subscribe((personalInformation: PersonalInformationInterface) => {
                if (!this.submitting && (!personalInformation.email || !personalInformation.password)) {
                    this.goToLogin();
                }
                this.email.setValue(personalInformation.email);
                this.temporaryPassword.setValue(personalInformation.password);
            });
        this.subscriptions.add(personalInformationSubscription);
    }

    private checkAuthenticationStatus(): void {
        const statusSubscription = this.authenticationDataService
            .getStatus$()
            .subscribe((status: AuthenticationStatusEnum) => {
                if (!this.submitting && status !== AuthenticationStatusEnum.TemporaryPassword) {
                    this.goToLogin();
                }
            });
        this.subscriptions.add(statusSubscription);
    }

    private autoLogin(): void {
        const credentials: LoginRequestInterface = {
            email: this.email.value,
            password: this.newPassword.value,
        };
        this.modalRef.close(credentials);
    }

    private goToLogin(): void {
        this.submitting = false;
        this.modalRef.close(true);
        this.router.navigate([AppRoutesEnum.Login]);
    }
}
