import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationEventService {
    private _login$: Subject<void>;
    private _loginToken$: Subject<void>;
    private _logout$: Subject<void>;
    private _validateEmailAddress$: Subject<void>;
    private _validateCredentials$: Subject<void>;
    private _validateTwoFactorAuthentication$: Subject<void>;

    public getLogin$(): Observable<void> {
        this._login$ = new Subject<void>();

        return this._login$.asObservable();
    }

    public onLoginSuccess(): void {
        this._login$.next();
        this._login$.complete();
    }

    public onLoginError(errors: ErrorInterface[]): void {
        this._login$.error(errors);
    }

    public getLoginToken$(): Observable<void> {
        this._loginToken$ = new Subject<void>();

        return this._loginToken$.asObservable();
    }

    public onLoginTokenSuccess(): void {
        this._loginToken$.next();
        this._loginToken$.complete();
    }

    public onLoginTokenError(errors: ErrorInterface[]): void {
        this._loginToken$.error(errors);
    }

    public getLogout$(): Observable<void> {
        this._logout$ = new Subject<void>();

        return this._logout$.asObservable();
    }

    public onLogoutSuccess(): void {
        this._logout$.next();
        this._logout$.complete();
    }

    public onLogoutError(errors: ErrorInterface[]): void {
        this._logout$.error(errors);
    }

    public getValidateCredentials$(): Observable<void> {
        this._validateCredentials$ = new Subject();
        return this._validateCredentials$.asObservable();
    }

    public onValidateCredentialsSuccess(): void {
        this._validateCredentials$.next();
        this._validateCredentials$.complete();
    }

    public onValidateCredentialsError(errors: ErrorInterface[]): void {
        this._validateCredentials$.error(errors);
    }

    public getValidateEmailAddress$(): Observable<void> {
        this._validateEmailAddress$ = new Subject();

        return this._validateEmailAddress$.asObservable();
    }

    public onValidateEmailAddressSuccess(): void {
        this._validateEmailAddress$.next();
        this._validateEmailAddress$.complete();
    }

    public onValidateEmailError(errors: ErrorInterface[]): void {
        this._validateEmailAddress$.error(errors);
    }

    public getValidateTwoFactorAuthentication$(): Observable<void> {
        this._validateTwoFactorAuthentication$ = new Subject();
        return this._validateTwoFactorAuthentication$.asObservable();
    }

    public onValidateTwoFactorAuthenticationSuccess(): void {
        this._validateTwoFactorAuthentication$.next();
        this._validateTwoFactorAuthentication$.complete();
    }

    public onValidateTwoFactorAuthenticationError(errors: ErrorInterface[]): void {
        this._validateTwoFactorAuthentication$.error(errors);
    }
}
