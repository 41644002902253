import { InvoiceDetailsInterface } from '../interfaces';
import { InvoiceActions, InvoiceActionType } from './invoice.actions';
import { INITIAL_INVOICE_STATE, InvoiceState } from './invoice.state';

export class InvoiceReducers {
    public static reducer(state: InvoiceState = INITIAL_INVOICE_STATE, action: InvoiceActionType): InvoiceState {
        switch (action.type) {
        case InvoiceActions.GetInvoicesSuccess: {
            return {
                ...state,
                invoices: action.payload.invoices,
                years: action.payload.years,
                selectedYear: action.payload.selectedYear,
                openInvoice: action.payload.openInvoice,
            };
        }

        case InvoiceActions.GetInvoiceDetailsSuccess: {
            const invoiceDetails: InvoiceDetailsInterface = {
                ...action.payload,
                invoiceId: action.payload.invoice.invoiceId,
            };

            return {
                ...state,
                invoiceDetails: [...state.invoiceDetails, invoiceDetails],
            };
        }

        case InvoiceActions.ResetInvoices: {
            return INITIAL_INVOICE_STATE;
        }

        default: {
            return state;
        }
        }
    }
}
