import { Component, EventEmitter, Output } from '@angular/core';
import { WucButtonModule, WucRackModule } from '@inshared/website-ui-components';
import { TwoFactorMethodEnum } from '../two-factor-method.enum';
import { twoFactorI18n } from '../two-factor-i18n';

@Component({
    selector: 'ins-two-factor-select-method',
    templateUrl: './select-method.component.html',
    standalone: true,
    imports: [
        WucRackModule,
        WucButtonModule
    ],
})
export class SelectMethodComponent {
    @Output() methodSelected = new EventEmitter<TwoFactorMethodEnum>();

    protected readonly twoFactorI18n = twoFactorI18n;
    protected readonly TwoFactorMethodEnum = TwoFactorMethodEnum;

    public isSubmitting= false;

    selectMethod(method: TwoFactorMethodEnum): void {
        this.isSubmitting = true;
        this.methodSelected.emit(method);
    }
}
