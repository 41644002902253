<wuc-card
    cardStyle="tertiary"
    radius="small"
    borderWidth="none"
    padding="medium"
>
    <wuc-rack>
        <b>{{ title }}</b>
        @if (messages.length === 1) {
            <span>{{ messages[0] }}</span>
        } @else {
            <wuc-list icon="dot">
                @for (message of messages; track message) {
                    <wuc-list-item>{{ message }}</wuc-list-item>
                }
            </wuc-list>
        }
        <wuc-button
            type="secondary"
            [isFullWidth]="true"
            [disabled]="isSubmitting"
            (buttonClick)="onResend(primaryResendMethod)"
        >{{ primaryButtonText }}
        </wuc-button>
        @if (secondaryResendMethod) {
            <wuc-button
                type="secondary"
                [isFullWidth]="true"
                [disabled]="isSubmitting"
                (buttonClick)="onResend(secondaryResendMethod)"
            >{{ secondaryButtonText }}
            </wuc-button>
        }
    </wuc-rack>
</wuc-card>
