import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import { ErrorInterface, LoginRequestInterface, NewModalModule } from 'outshared-lib';
import {
    WucButtonModule,
    WucCardModule,
    WucIconUiModule,
    WucInputAlertModule,
    WucLinkModule,
    WucModalModule,
    WucRackModule
} from '@inshared/website-ui-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TwoFactorService } from './two-factor.service';
import { TwoFactorMethodEnum } from './two-factor-method.enum';
import { ResendComponent } from './resend';
import { TwoFactorDataInterface } from './two-factor-data.interface';
import { twoFactorI18n } from './two-factor-i18n';
import { SelectMethodComponent } from './select-method';
import { OtpInputComponent } from './otp-input';
import { isPlatformServer } from '@angular/common';
import { IntersectionObserverService, ScrollService } from '@inshared/shared/util';
import { take } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'ins-two-factor',
    templateUrl: './two-factor.component.html',
    styleUrl: './two-factor.component.scss',
    standalone: true,
    imports: [
        NewModalModule,
        WucButtonModule,
        WucIconUiModule,
        ReactiveFormsModule,
        WucModalModule,
        WucInputAlertModule,
        WucLinkModule,
        WucRackModule,
        FormsModule,
        WucCardModule,
        ResendComponent,
        SelectMethodComponent,
        OtpInputComponent,
    ],
})
export class TwoFactorComponent implements OnInit {
    @Input() credentials!: LoginRequestInterface;
    @Output() codeSuccess = new EventEmitter<void>();
    @Output() sessionTimeout = new EventEmitter<void>();
    @ViewChild(OtpInputComponent) private otpInputComponent: OtpInputComponent;

    #twoFactorService = inject(TwoFactorService);
    #changeSetectorRef = inject(ChangeDetectorRef);
    #platformId = inject(PLATFORM_ID);
    #scrollService = inject(ScrollService);
    #intersectionObserverService = inject(IntersectionObserverService);

    codeRequested = false;
    submitting = false;
    errors: string[] = [];
    otp = '';
    resendInfoVisible = false;
    data! : TwoFactorDataInterface;

    ngOnInit() {
        this.#twoFactorService.data$.subscribe((data) => {
            this.data = data;
            if (!isPlatformServer(this.#platformId)) {
                setTimeout(() => {
                        this.sessionTimeout.emit();
                    },
                    this.data.sessionExpireDate.getTime() - new Date().getTime()
                );
            }
            this.data.email = this.credentials.email;
            this.codeRequested = !!this.data.generalMessage;
            if (!this.data.phoneNumber) {
                this.data.selectedMethod = TwoFactorMethodEnum.Email;
                this.requestCode(TwoFactorMethodEnum.Email);
            } else if (this.data.generalMessage) {
                this.data.selectedMethod = TwoFactorMethodEnum.Unknown;
            }
            this.#changeSetectorRef.detectChanges();
        });
        this.scrollIntoView();
    }

    scrollIntoView() {
        if (!isPlatformServer(this.#platformId)) {
            this.#intersectionObserverService.isScreenSmall$.pipe(
                take(1),
                filter((isSmall: boolean) => isSmall),
            ).subscribe(() => {
                this.#scrollService.scrollElementOffset('#mfa-top', 0, 100);
            });
        }
    }

    onCodeChange() {
        this.errors = [];
        if (this.otp.length === 6) {
            this.submitting = true;
            this.#twoFactorService.validateCode(this.data.relationId, this.otp).subscribe((errors: ErrorInterface[]) => {
                if (errors?.length) {
                    this.errors = errors.map((error) => error.message);
                    this.otp = '';
                    this.submitting = false;
                    this.setInputFocus();
                } else {
                    this.codeSuccess.emit();
                }
                this.#changeSetectorRef.detectChanges();
            });
        }
    }

    requestCode(method: TwoFactorMethodEnum): void {
        this.otp = '';
        this.errors = [];
        this.#twoFactorService.requestCode(this.data.relationId, method).subscribe((errors: ErrorInterface[]) => {
            if (errors?.length) {
                this.errors = errors.map((error) => error.message);
            } else {
                this.resendInfoVisible = false;
                this.data.selectedMethod = method;
            }
            this.codeRequested = true;
            this.#changeSetectorRef.detectChanges();
            this.setInputFocus();
        });

    }

    protected readonly TwoFactorMethodEnum = TwoFactorMethodEnum;

    onResend(method: TwoFactorMethodEnum) {
        this.requestCode(method)
    }

    setInputFocus() {
        setTimeout(() => this.otpInputComponent?.setFocus());
    }

    protected readonly twoFactorI18n = twoFactorI18n;
}
