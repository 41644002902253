import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoginContainer } from './login.container';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { ShowPasswordModule } from '@app-de/shared/directives/show-password/show-password.module';
import { SanitizePipeModule } from '@app-de/shared/pipes/sanitize/sanitize.module';
import { TwoFactorComponent, TwoFactorService } from '@authentication/feature-login';

@NgModule({
    imports: [CommonModule, RouterModule, SanitizePipeModule, CynoFormModule, ShowPasswordModule, TwoFactorComponent],
    declarations: [LoginContainer, LoginComponent],
    exports: [LoginContainer, LoginComponent],
    providers: [
        TwoFactorService,
    ]
})
export class LoginModule {}
