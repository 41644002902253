import { ErrorInterface, ErrorLevelEnum, HttpStatusEnum, mapSuccessIndication, ResponseInterface } from '../../core';
import { ApiAuthenticationStatusEnum, AuthenticationStatusEnum } from '../enums';
import {
    ApiLoginRequest,
    ApiSessionResponse,
    AuthenticationErrorInterface,
    isLegacySessionResponse,
    LegacyApiSessionResponse,
    LoginRequestInterface,
} from '../interfaces';

const AUTHENTICATION_ERROR_DISCRIMINATOR = 'status';

// Need to exclude several not used cases here from code coverage inclusion
/* istanbul ignore next */
function mapAuthenticationStatusEnum(apiStatus: ApiAuthenticationStatusEnum): AuthenticationStatusEnum {
    switch (apiStatus) {
        case ApiAuthenticationStatusEnum.LoggedIn:
            return AuthenticationStatusEnum.LoggedIn;
        case ApiAuthenticationStatusEnum.LoggedOut:
            return AuthenticationStatusEnum.LoggedOut;
        case ApiAuthenticationStatusEnum.Blocked:
            return AuthenticationStatusEnum.Blocked;
        case ApiAuthenticationStatusEnum.Disbarred:
            return AuthenticationStatusEnum.Disbarred;
        case ApiAuthenticationStatusEnum.InvalidUsername:
            return AuthenticationStatusEnum.InvalidUsername;
        case ApiAuthenticationStatusEnum.InvalidPassword:
            return AuthenticationStatusEnum.InvalidPassword;
        case ApiAuthenticationStatusEnum.GeneratedAccount:
            return AuthenticationStatusEnum.GeneratedAccount;
        case ApiAuthenticationStatusEnum.Timeout:
            return AuthenticationStatusEnum.Timeout;
        case ApiAuthenticationStatusEnum.UnactivatedAndBlocked:
            return AuthenticationStatusEnum.UnactivatedAndBlocked;
        case ApiAuthenticationStatusEnum.TemporaryPassword:
            return AuthenticationStatusEnum.TemporaryPassword;
        case ApiAuthenticationStatusEnum.ChangeEmail:
            return AuthenticationStatusEnum.ChangeEmail;
        case ApiAuthenticationStatusEnum.WelcomeDialogue:
            return AuthenticationStatusEnum.WelcomeDialogue;
        case ApiAuthenticationStatusEnum.TwoFactor:
            return AuthenticationStatusEnum.TwoFactor;
    }
}

export function mapLoginRequest(request: LoginRequestInterface): ApiLoginRequest {
    return {
        email_address: request?.email,
        password: request?.password?.trim(),
        doorlopende_inlog_indicatie: mapSuccessIndication(!!request.stayLoggedIn),
        login_token: request?.loginToken,
    };
}

function mapAuthenticationStatuses(response: ResponseInterface): ResponseInterface {
    const data: ApiSessionResponse | LegacyApiSessionResponse = response.data;

    switch (data.status_code) {
        case ApiAuthenticationStatusEnum.LoggedIn:
            break;
        case ApiAuthenticationStatusEnum.TemporaryPassword:
        case ApiAuthenticationStatusEnum.GeneratedAccount:
        case ApiAuthenticationStatusEnum.ChangeEmail:
        case ApiAuthenticationStatusEnum.TwoFactor:
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            response.errors = [createLoginError(response)];
            break;
        case ApiAuthenticationStatusEnum.Blocked:
            response.statusCode = HttpStatusEnum.Unauthorized;
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            response.errors = [createLoginError(response)];
            break;
        default:
            response.statusCode = HttpStatusEnum.Unauthorized;
            response.errors = response.errors.map((error: ErrorInterface): AuthenticationErrorInterface => {
                return {
                    ...error,
                    status: response.data?.status_code ?? ApiAuthenticationStatusEnum.InvalidUsername,
                };
            });
            break;
    }
    return response;
}

export function mapAuthenticationResponse(response: ResponseInterface): ResponseInterface {
    switch (response.statusCode) {
        case HttpStatusEnum.OK:
            return mapAuthenticationStatuses(response);
        case HttpStatusEnum.Unauthorized:
            return mapAuthenticationStatuses(response);
        default:
            throw response.errors;
    }
}

export function mapTwoFactorAuthenticationResponse(response: ResponseInterface) {
    if (response.data?.status_code !== ApiAuthenticationStatusEnum.LoggedIn) {
        throw response.errors;
    }
    return response;
}

function createLoginError(response: ResponseInterface): AuthenticationErrorInterface {
    const message = isLegacySessionResponse(response.data) ? response.data.status : response.data.description;
    return {
        status: mapAuthenticationStatusEnum(response.data.status_code),
        id: `authentication_${response.statusCode}`,
        element: response.data.type,
        level: ErrorLevelEnum.Info,
        message: message,
    };
}

export function isAuthenticationError(error: any): error is AuthenticationErrorInterface {
    return error.hasOwnProperty(AUTHENTICATION_ERROR_DISCRIMINATOR);
}

export function hasAuthenticationError(errors: ErrorInterface[], status: AuthenticationStatusEnum): boolean {
    return (
        errors.find((error: ErrorInterface | AuthenticationErrorInterface) => {
            return isAuthenticationError(error) && error.status === status;
        }) !== undefined
    );
}

export function hasTemporaryPasswordError(errors: ErrorInterface[]): boolean {
    return hasAuthenticationError(errors, AuthenticationStatusEnum.TemporaryPassword);
}

export function hasGeneratedAccountError(errors: ErrorInterface[]): boolean {
    return hasAuthenticationError(errors, AuthenticationStatusEnum.GeneratedAccount);
}

export function hasChangedEmailError(errors: ErrorInterface[]): boolean {
    return hasAuthenticationError(errors, AuthenticationStatusEnum.ChangeEmail);
}

export function hasTwoFactorError(errors: ErrorInterface[]): boolean {
    return hasAuthenticationError(errors, AuthenticationStatusEnum.TwoFactor);
}

export function getAuthenticationStatus(errors: ErrorInterface[]): AuthenticationStatusEnum {
    errors?.filter(isAuthenticationError).forEach((error: AuthenticationErrorInterface) => {
        return error.status;
    });

    return AuthenticationStatusEnum.LoggedOut;
}
