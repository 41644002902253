import { createReducer, on } from '@ngrx/store';
import {
    mergeClaimObjectDetailsUtility,
    mergeObjectDetailsUtility,
    mergeSavedClaimObjectsUtility,
    removeSavedClaimObjectsUtility,
} from '../utilities';
import { mergeClaimObjectElementsUtility } from '../utilities/merge-claim-object-elements.utility';
import * as ClaimObjectsActions from './claim-objects.actions';
import { ClaimObjectsState, INITIAL_CLAIM_OBJECTS_STATE } from './claim-objects.state';

export const claimObjectsReducer = createReducer(
    INITIAL_CLAIM_OBJECTS_STATE,
    on(
        ClaimObjectsActions.getClaimObjectsSuccess,
        (state, { response }): ClaimObjectsState => ({
            ...state,
            claimObjects: response.claimObjects,
        })
    ),
    on(
        ClaimObjectsActions.resumeClaimObjectsSuccess,
        (state, { response }): ClaimObjectsState => ({
            ...state,
            savedClaimObjects: mergeSavedClaimObjectsUtility(response, response),
        })
    ),
    on(
        ClaimObjectsActions.getClaimObjectDetailsSuccess,
        (state, { response }): ClaimObjectsState => ({
            ...state,
            claimObjectDetails: response.claimObjectDetails
        })
    ),
    on(
        ClaimObjectsActions.updateClaimObjectDetailsSuccess,
        (state, { response }): ClaimObjectsState => ({
            ...state,
            claimObjectDetails: mergeObjectDetailsUtility(state.claimObjectDetails, response.claimObjectDetails)
        })
    ),
    on(
        ClaimObjectsActions.reloadClaimObjectDetailsSuccess,
        (state, { response }): ClaimObjectsState => ({
            ...state,
            claimObjectDetails: mergeClaimObjectDetailsUtility(state.claimObjectDetails, response.claimObjectDetails),
            claimObjectElements: mergeClaimObjectElementsUtility(
                state.claimObjectElements,
                response.claimObjectElements
            ),
        })
    ),
    on(
        ClaimObjectsActions.saveClaimObjectSuccess,
        (state, { response }): ClaimObjectsState => ({
            ...state,
            savedClaimObjects: mergeSavedClaimObjectsUtility(state.savedClaimObjects, response.savedClaimObjects),
        })
    ),
    on(
        ClaimObjectsActions.removeClaimObjectSuccess,
        (state, { response }): ClaimObjectsState => ({
            ...state,
            savedClaimObjects: removeSavedClaimObjectsUtility(state.savedClaimObjects, response.removedClaimObjects),
        })
    ),
    on(ClaimObjectsActions.resetClaimObjects, (): ClaimObjectsState => INITIAL_CLAIM_OBJECTS_STATE)
);
