import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CredentialsInterface, LoginRequestInterface, LoginTokenRequestInterface } from '../interfaces';
import {
    Authentication,
    LoginAction,
    LoginTokenAction,
    LogoutAction,
    RequestSessionAction,
    ValidateCredentialsAction,
    ValidateEmailAction,
    ValidateTwoFactorAuthenticationAction,
} from '../store';
import { AuthenticationEventService } from './authentication.event.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationActionService {
    constructor(
        private store$: Store<Authentication>,
        private eventService: AuthenticationEventService,
    ) {}

    public login$(loginRequest: LoginRequestInterface): Observable<void> {
        this.store$.dispatch(new LoginAction({ request: loginRequest }));

        return this.eventService.getLogin$();
    }

    public loginToken$(loginRequest: LoginTokenRequestInterface): Observable<void> {
        this.store$.dispatch(new LoginTokenAction({ request: loginRequest }));

        return this.eventService.getLoginToken$();
    }

    public refreshSession(): void {
        this.store$.dispatch(new RequestSessionAction());
    }

    public logout$(): Observable<void> {
        this.store$.dispatch(new LogoutAction());

        return this.eventService.getLogout$();
    }

    public validateCredentials$(request: CredentialsInterface): Observable<void> {
        this.store$.dispatch(new ValidateCredentialsAction({ request }));

        return this.eventService.getValidateCredentials$();
    }

    public validateEmail$(code: string): Observable<void> {
        this.store$.dispatch(new ValidateEmailAction({ code }));

        return this.eventService.getValidateEmailAddress$();
    }

    public validateTwoFactorAuthentication$(relationId: number, otp: string): Observable<void> {
        this.store$.dispatch(new ValidateTwoFactorAuthenticationAction({ relationId, otp }));
        return this.eventService.getValidateTwoFactorAuthentication$();
    }
}
