import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService, ResponseInterface } from '../../core';
import { CredentialsInterface, LoginRequestInterface, LoginTokenRequestInterface } from '../interfaces';
import {
    mapAuthenticationResponse,
    mapLoginRequest,
    mapLoginTokenRequest,
    mapSessionResponse,
    mapTokenRequest,
    mapTwoFactorAuthenticationResponse,
    mapValidateCredentialsResponse,
    mapValidateEmailAddressRequest,
} from '../mappers';

const RESOURCES = {
    LOGIN: 'en-gb/relation-authorization/validate/account',
    LOGIN_TOKEN: 'en-gb/relation-authorization/provide/logintoken',
    VALIDATE_EMAIL_ADDRESS: 'en-gb/relation-authorization/validate/emailaddress',
    LOGOUT: 'en-gb/relation-authorization/log_out/account',
    REQUEST_SESSION: 'en-gb/relation-authorization/request/session',
    REQUEST_SESSION_WITH_TOKEN: 'en-gb/relation-authorization/validate/doorlopend-inlogtoken',
    SEND_2FA_CODE: 'en-gb/relation-authorization/send/twofactorauthenticationcode',
    VALIDATE_2FA_CODE: 'en-gb/relation-authorization/validate/twofactorauthenticationcode',
};

@Injectable({
    providedIn: 'root',
})
export class AuthenticationApiService {
    constructor(private apiService: ApiService) {}

    public extendSession$(): Observable<ResponseInterface> {
        return this.apiService
            .postResponse$(RESOURCES.REQUEST_SESSION)
            .pipe(map(mapAuthenticationResponse), map(mapSessionResponse));
    }

    public extendSessionWithToken$(token: string): Observable<ResponseInterface> {
        const payload = mapTokenRequest(token);

        return this.apiService
            .postResponse$(RESOURCES.REQUEST_SESSION_WITH_TOKEN, payload)
            .pipe(map(mapAuthenticationResponse), map(mapSessionResponse));
    }

    public login$(loginRequest: LoginRequestInterface): Observable<ResponseInterface> {
        const payload = mapLoginRequest(loginRequest);

        return this.apiService
            .postResponse$(RESOURCES.LOGIN, payload)
            .pipe(map(mapAuthenticationResponse), map(mapSessionResponse));
    }

    public validateCredentials$(request: CredentialsInterface): Observable<void> {
        const payload = mapLoginRequest(request);

        return this.apiService
            .postResponse$(RESOURCES.LOGIN, payload)
            .pipe(map(mapAuthenticationResponse), map(mapValidateCredentialsResponse));
    }

    public validateEmailAddress$(code: string): Observable<void> {
        const payload = mapValidateEmailAddressRequest(code);

        return this.apiService.post$(RESOURCES.VALIDATE_EMAIL_ADDRESS, payload);
    }

    public provideLoginToken$(request: LoginTokenRequestInterface): Observable<void> {
        const payload = mapLoginTokenRequest(request);

        return this.apiService.post$(RESOURCES.LOGIN_TOKEN, payload);
    }

    public logout$(): Observable<void> {
        return this.apiService.post$<void>(RESOURCES.LOGOUT);
    }

    public request2faCode(request: { relationId: number; method: string }): Observable<void> {
        const payload = {
            relation_id: request.relationId,
            channel_code: request.method,
        };

        return this.apiService.post$(RESOURCES.SEND_2FA_CODE, payload);
    }

    public validate2faCode(request: { relationId: number; otp: string }): Observable<ResponseInterface> {
        const payload = {
            relation_id: request.relationId,
            verification_code: request.otp,
        };

        return this.apiService
            .postResponse$(RESOURCES.VALIDATE_2FA_CODE, payload)
            .pipe(map(mapTwoFactorAuthenticationResponse), map(mapSessionResponse));
    }
}
