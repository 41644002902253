import { SavedClaimObjectInterface } from '../interfaces/saved-claim-object.interface';

export function mergeSavedClaimObjectsUtility(
    stateSavedClaimObjects: SavedClaimObjectInterface[],
    responseSavedClaimObjects: SavedClaimObjectInterface[]
): SavedClaimObjectInterface[] {
    const savedClaimObjects = Object.assign([], stateSavedClaimObjects);
    const responseSavedClaimObject = responseSavedClaimObjects[0];
    if (!responseSavedClaimObject) {
        return [];
    }

    savedClaimObjects.push(responseSavedClaimObject);

    // Filter duplicates
    return [...new Set(savedClaimObjects)];
}
