import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    DownloadInvoiceInterface,
    GetInvoiceDetailsPayloadInterface,
    GetInvoicesPayloadInterface,
} from '../interfaces';
import {
    DownloadInvoiceAction,
    GetInvoiceDetailsAction,
    GetInvoicesAction,
    ResetInvoicesAction,
} from '../store/invoice.actions';
import { Invoice } from '../store/invoice.state';
import { InvoiceEventService } from './invoice.event.service';

@Injectable({
    providedIn: 'root',
})
export class InvoiceActionService {
    constructor(private store$: Store<Invoice>, private invoiceEventService: InvoiceEventService) {}

    public getInvoices$(payload?: GetInvoicesPayloadInterface): Observable<void> {
        this.store$.dispatch(new GetInvoicesAction(payload));
        return this.invoiceEventService.getInvoices$();
    }

    public getInvoiceDetails$(payload: GetInvoiceDetailsPayloadInterface): Observable<void> {
        this.store$.dispatch(new GetInvoiceDetailsAction(payload));
        return this.invoiceEventService.getInvoiceDetails$();
    }

    public downloadInvoice$({ invoiceId }: { invoiceId: string }): Observable<DownloadInvoiceInterface> {
        this.store$.dispatch(new DownloadInvoiceAction({ invoiceId }));
        return this.invoiceEventService.downloadInvoice$();
    }

    public resetInvoices(): void {
        this.store$.dispatch(new ResetInvoicesAction());
    }
}
