export enum AuthenticationStatusEnum {
    LoggedIn = 'LoggedIn',
    TemporaryPassword = 'TemporaryPassword',
    Blocked = 'Blocked',
    Disbarred = 'Disbarred',
    InvalidUsername = 'InvalidUsername',
    InvalidPassword = 'InvalidPassword',
    GeneratedAccount = 'GeneratedAccount',
    LoggedOut = 'LoggedOut',
    Timeout = 'TimeOut',
    SessionExpired = 'SessionExpired',
    UnactivatedAndBlocked = 'UnactivatedAndBlocked',
    WelcomeDialogue = 'WelcomeDialogue',
    ChangeEmail = 'ChangeEmail',
    TwoFactor = 'TwoFactorAuthentication',

}
