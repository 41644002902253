import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucListComponent } from '../../common';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-radio',
    templateUrl: 'input-radio.component.html',
    styleUrls: ['input-radio.component.scss', 'input-radio-info.component.scss'],
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputRadioComponent,
        },
    ],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
})
export class WucInputRadioComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface, OnInit {
    @Input() public value?: string | {};
    @Input() public isChildOfInput: boolean = false;
    @Input() public testId?: string;
    /**
     * Do not use 'id' as it's a reserved attribute name
     */
    @Input() public inputId?: string;

    @Input()
    public get checked(): boolean {
        return this.inputElement?.nativeElement?.checked || false;
    }

    @Input() public infoTextVisible: boolean = false;
    @Output() public infoTextVisibleChanged: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    @ViewChild('input', { static: false }) private inputElement!: ElementRef;

    public static selector = 'wuc-input-radio';
    public static nextId = 0;

    public isDisabled: boolean = false;
    public formValue?: string | {};

    public onChange!: (_: string | {}) => void;
    public onTouched!: () => void;

    public get boldLabel(): boolean {
        return this.checked && !!this.listComponent;
    }

    constructor(
        private elementRef: ElementRef,
        @Optional() public override ngControl: NgControl,
        @Optional() private listComponent: WucListComponent
    ) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        if (!this.inputId) {
            this.inputId = `${WucInputRadioComponent.selector}-${WucInputRadioComponent.nextId++}`;
        }
    }

    public registerOnChange(onChange: (value: string | {}) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public writeValue(value: string | {}): void {
        this.formValue = value;
        if (this.inputElement?.nativeElement) {
            this.inputElement.nativeElement.checked = this.value === this.formValue;
        }
        this.infoTextVisible = this.value === this.formValue;
        this.infoTextVisibleChanged.emit(this.infoTextVisible);
    }

    public onValueChange(value: string | {}): void {
        this.formValue = value;
        this.onChange(value);
        this.onTouched();
        this.infoTextVisible = this.value === this.formValue;
        this.infoTextVisibleChanged.emit(this.infoTextVisible);
    }

    public toggleInfo(): void {
        this.infoTextVisible = !this.infoTextVisible;
        this.infoTextVisibleChanged.emit(this.infoTextVisible);
    }

    public onInfoClick(event: MouseEvent): void {
        event.preventDefault();
        this.toggleInfo();
    }

    public onInputClick(event: MouseEvent): void {
        event.stopPropagation();
    }
}
