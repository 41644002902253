import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { InvoiceDetailsInterface } from '../interfaces';
import { Invoice, InvoiceState, INVOICE_FEATURE } from './invoice.state';

export class InvoiceSelectors {
    public static getInvoiceState = createFeatureSelector<InvoiceState>(INVOICE_FEATURE);

    // Only for testing
    public static getState = createSelector(InvoiceSelectors.getInvoiceState, (state: InvoiceState) => state);

    public static invoices = createSelector(InvoiceSelectors.getInvoiceState, (state: InvoiceState) => state.invoices);

    public static openInvoice = createSelector(
        InvoiceSelectors.getInvoiceState,
        (state: InvoiceState) => state.openInvoice
    );

    public static years = createSelector(InvoiceSelectors.getInvoiceState, (state: InvoiceState) => state.years);
    public static selectedYear = createSelector(InvoiceSelectors.getInvoiceState, (state: InvoiceState) => state.selectedYear);

    public static invoiceDetails: Selector<Invoice, InvoiceDetailsInterface[]> = createSelector(
        InvoiceSelectors.getInvoiceState,
        (state: InvoiceState) => state.invoiceDetails
    );
}
