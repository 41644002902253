<h1>{{ twoFactorI18n.askSmsOrMailTitle }}</h1>
<wuc-rack [columnsMobile]="1" [columnsDesktop]="2">
    <wuc-button
        type="primary"
        [isFullWidth]="true"
        [disabled]="isSubmitting"
        (buttonClick)="selectMethod(TwoFactorMethodEnum.SMS)"
    >{{ twoFactorI18n.smsButton }}
    </wuc-button>
    <wuc-button
        type="secondary"
        [isFullWidth]="true"
        [disabled]="isSubmitting"
        (buttonClick)="selectMethod(TwoFactorMethodEnum.Email)"
    >{{ twoFactorI18n.mailButton }}
    </wuc-button>
</wuc-rack>
