export enum ApiAuthenticationStatusEnum {
    Blocked = 'GBLK',
    Disbarred = 'GROY',
    InvalidUsername = 'VGBR',
    InvalidPassword = 'VWWD',
    GeneratedAccount = 'INIT',
    LoggedIn = 'ILOG',
    LoggedOut = 'ULOG',
    Timeout = 'SETI',
    UnactivatedAndBlocked = 'GENG',
    TemporaryPassword = 'TLOG',
    ChangeEmail = 'WEML',
    WelcomeDialogue = 'WDLG',
    TwoFactor = 'BTFA',
}
