import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TwoFactorMethodEnum } from '../two-factor-method.enum';
import { WucButtonModule, WucCardModule, WucListModule, WucRackModule } from '@inshared/website-ui-components';
import { TwoFactorDataInterface } from '../two-factor-data.interface';
import { twoFactorI18n } from '../two-factor-i18n';

@Component({
    selector: 'ins-two-factor-resend',
    templateUrl: './resend.component.html',
    standalone: true,
    imports: [
        WucCardModule,
        WucRackModule,
        WucButtonModule,
        WucListModule
    ],
})
export class ResendComponent {
    @Input() set data(data: TwoFactorDataInterface) {
        switch (data.selectedMethod) {
            case TwoFactorMethodEnum.SMS:
                this.title = twoFactorI18n.smsNotRecievedTitle;
                this.messages = twoFactorI18n.smsNotRecievedText.replace('#', data.phoneNumber).split('|');
                this.primaryResendMethod = TwoFactorMethodEnum.SMS;
                this.primaryButtonText = twoFactorI18n.resendBySmsButton;
                this.secondaryResendMethod = TwoFactorMethodEnum.Email;
                this.secondaryButtonText = twoFactorI18n.orSendByMailButton;
                break;
            case TwoFactorMethodEnum.Email:
                this.title = twoFactorI18n.mailNotRecievedTitle;
                this.messages = twoFactorI18n.mailNotRecievedText.replace('#', data.email).split('|');
                this.primaryResendMethod = TwoFactorMethodEnum.Email;
                this.primaryButtonText = twoFactorI18n.resendByMailButton;
                this.secondaryResendMethod = !!data.phoneNumber ? TwoFactorMethodEnum.SMS : undefined;
                this.secondaryButtonText = twoFactorI18n.orSendBySmsButton;
                break;
            case TwoFactorMethodEnum.Unknown:
                this.title = twoFactorI18n.codeNotRecievedTitle;
                this.messages = twoFactorI18n.codeNotRecievedText.split('|');
                this.primaryResendMethod = TwoFactorMethodEnum.Email;
                this.primaryButtonText = twoFactorI18n.mailButton;
                this.secondaryResendMethod = !!data.phoneNumber ? TwoFactorMethodEnum.SMS : undefined;
                this.secondaryButtonText = twoFactorI18n.smsButton;
                break;
        }
    }

    @Output() resend = new EventEmitter<TwoFactorMethodEnum>();

    title!: string;
    messages!: string[];
    primaryResendMethod? :TwoFactorMethodEnum;
    primaryButtonText!: string;
    secondaryResendMethod? :TwoFactorMethodEnum;
    secondaryButtonText?: string;
    isSubmitting= false;

    onResend(method: TwoFactorMethodEnum): void {
        this.isSubmitting = true;
        this.resend.emit(method);
        setTimeout(()=> this.isSubmitting = false, 2000);
    }
}
