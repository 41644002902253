<div id="mfa-top"></div>
@if (data) {
    @if (!data.selectedMethod) {
        <ins-two-factor-select-method (methodSelected)="requestCode($event)" />
        @if (submitting) {
            <div class="loading">
                <svg class="wuc-icon-ui">
                    <use href="icons/icons-ui.svg#loading"></use>
                </svg>
                {{ twoFactorI18n.validating }}
            </div>
        }
        @if (errors.length) {
            <wuc-input-alert icon="cross-closed" [messages]="errors"></wuc-input-alert>
        }
    } @else {
        <span class="h1">{{ twoFactorI18n.enterCodeTitle }}</span>
        <wuc-rack [columnsDesktop]="1">
            @switch (data.selectedMethod) {
                @case (TwoFactorMethodEnum.SMS) {
                    <span>{{ twoFactorI18n.smsConformationText }}</span>
                }
                @case (TwoFactorMethodEnum.Email) {
                    <span>{{ twoFactorI18n.mailConformationText }}</span>
                }
                @case (TwoFactorMethodEnum.Unknown) {
                    <span>{{ data.generalMessage }}</span>
                }
            }
            <ins-two-factor-input
                [disabled]="!codeRequested || submitting"
                [(ngModel)]="otp"
                (ngModelChange)="onCodeChange()"
            />
            @if (errors.length) {
                <wuc-input-alert icon="cross-closed" [messages]="errors"></wuc-input-alert>
            }
            @if (submitting) {
                <div class="loading">
                    <svg class="wuc-icon-ui">
                        <use href="icons/icons-ui.svg#loading"></use>
                    </svg>
                    {{ twoFactorI18n.validating }}
                </div>
            } @else {
                @if (resendInfoVisible) {
                    <ins-two-factor-resend [data]="data" (resend)="onResend($event)" />
                } @else {
                    <wuc-link type="primary" (clicked)="resendInfoVisible = true">
                        @switch (data.selectedMethod) {
                            @case (TwoFactorMethodEnum.SMS) {
                                <span>{{ twoFactorI18n.smsNotRecievedButton }}</span>
                            }
                            @case (TwoFactorMethodEnum.Email) {
                                <span>{{ twoFactorI18n.mailNotRecievedButton }}</span>
                            }
                            @case (TwoFactorMethodEnum.Unknown) {
                                <span>{{ twoFactorI18n.codeNotRecievedButton }}</span>
                            }
                        }
                    </wuc-link>
                }
            }
        </wuc-rack>
    }
}

