<ng-container *ngIf="coverageId === CoverageIdEnum.KfzHaftpflicht">
    <ins-tabset customClass="tabset tabset--dark">
        <ins-tab title="Versichert" [active]="true">
            <ins-list-flat icon="check" [svg]="true">
                <ins-list-flat-item>
                    Schäden, die Sie mit Ihrem Auto (oder Anhänger) anderen Autos, Personen oder Gegenständen zufügen
                </ins-list-flat-item>
                <ins-list-flat-item>
                    Schäden durch ein Fahrzeug, das mit dem versicherten Fahrzeug (ab-)geschleppt wird
                </ins-list-flat-item>
                <ins-list-flat-item>
                    Mallorca-Police: im Ausland (im Bereich der Grünen Karte) verursachte Schäden, soweit das fremde
                    Fahrzeug nicht ausreichend haftpflichtversichert ist
                </ins-list-flat-item>
                <ins-list-flat-item>
                    Notfallservice: Ist Ihr Auto nach einem Unfall nicht fahrfähig, bringen wir es in eine Werkstatt
                </ins-list-flat-item>
            </ins-list-flat>
        </ins-tab>
        <ins-tab title="Nicht versichert">
            <ins-list-flat icon="dot" [svg]="true">
                <ins-list-flat-item>Schäden am eigenen Auto oder Anhänger</ins-list-flat-item>
                <ins-list-flat-item>Vandalismus und Diebstahl</ins-list-flat-item>
                <ins-list-flat-item>Vorsätzlich verursachte Schäden</ins-list-flat-item>
                <ins-list-flat-item>Schäden an Gegenständen, die im Auto befördert wurden</ins-list-flat-item>
                <ins-list-flat-item>Personenschäden des Fahrers</ins-list-flat-item>
            </ins-list-flat>
        </ins-tab>
        <ins-tab title="Selbstbeteiligung">
            <p>€ 0</p>
        </ins-tab>
    </ins-tabset>
</ng-container>

<ng-container *ngIf="coverageId === CoverageIdEnum.Teilkasko">
    <ins-tabset customClass="tabset tabset--dark">
        <ins-tab title="Versichert" [active]="true">
            <p>Schäden an Ihrem Auto:</p>
            <ins-list-flat icon="check" [svg]="true">
                <ins-list-flat-item>
                    Schäden, unmittelbar verursacht durch Sturm, Hagel, Blitzschlag, Überschwemmung, Lawinen und
                    Erdrutsch
                </ins-list-flat-item>
                <ins-list-flat-item>Schäden durch Brand und Explosion</ins-list-flat-item>
                <ins-list-flat-item>Diebstahl (vollendet oder versucht)</ins-list-flat-item>
                <ins-list-flat-item>Zusammenstoß mit Tieren während der Fahrt</ins-list-flat-item>
                <ins-list-flat-item>Schäden und Folgeschäden durch Tierbiss bis 10.000€</ins-list-flat-item>
                <ins-list-flat-item>Bruch der Verglasung</ins-list-flat-item>
            </ins-list-flat>
            <p>Notfallservice: Ist Ihr Auto nach einem Unfall nicht fahrfähig, bringen wir es in eine Werkstatt.</p>
        </ins-tab>
        <ins-tab title="Nicht versichert sind">
            <ins-list-flat icon="dot" [svg]="true">
                <ins-list-flat-item>Schäden an Ihrem Auto durch Unfall oder Vandalismus</ins-list-flat-item>
                <ins-list-flat-item>Schäden, die Sie vorsätzlich verursachen</ins-list-flat-item>
                <ins-list-flat-item>Schäden bei Kraftfahrzeugrennen</ins-list-flat-item>
            </ins-list-flat>
        </ins-tab>
        <ins-tab title="Selbstbeteiligung">
            <p>150 € pro Schaden</p>
            <p>0 € bei Glasbruch ohne Scheibenaustausch</p>
        </ins-tab>
    </ins-tabset>
</ng-container>

<ng-container *ngIf="coverageId === CoverageIdEnum.Vollkasko">
    <ins-tabset customClass="tabset tabset--dark">
        <ins-tab title="Versichert" [active]="true">
            <p>Alle Schäden an Ihrem Auto:</p>
            <ins-list-flat icon="check" [svg]="true">
                <ins-list-flat-item>
                    alle Leistungen, die auch in der Teilkasko enthalten sind, zum Beispiel:
                </ins-list-flat-item>
                <ins-list-flat icon="check" [svg]="true">
                    <ins-list-flat-item>
                        Schäden, unmittelbar verursacht durch Sturm, Hagel, Blitzschlag, Überschwemmung, Lawinen und
                        Erdrutsch
                    </ins-list-flat-item>
                    <ins-list-flat-item>Schäden durch Brand und Explosion</ins-list-flat-item>
                    <ins-list-flat-item>Diebstahl (vollendet oder versucht)</ins-list-flat-item>
                </ins-list-flat>
            </ins-list-flat>
            <p>Außerdem:</p>
            <ins-list-flat icon="check" [svg]="true">
                <ins-list-flat-item>Schäden durch Unfall</ins-list-flat-item>
                <ins-list-flat-item>Vandalismus</ins-list-flat-item>
            </ins-list-flat>
        </ins-tab>
        <ins-tab title="Nicht versichert">
            <ins-list-flat icon="dot" [svg]="true">
                <ins-list-flat-item>Schäden, die Sie vorsätzlich verursachen</ins-list-flat-item>
                <ins-list-flat-item>Schäden bei Kraftfahrzeugrennen</ins-list-flat-item>
            </ins-list-flat>
        </ins-tab>
        <ins-tab title="Selbstbeteiligung">
            <p>300 € pro Schaden</p>
            <p>0 € bei Glasbruch ohne Scheibenaustausch</p>
            <p>
                Ist jemand anderes für den Schaden an Ihrem Auto verantwortlich, und können wir den Schaden von der
                Gegenseite einfordern? Dann fällt für diese Schadensmeldung keine Selbstbeteiligung an.
            </p>
        </ins-tab>
    </ins-tabset>
</ng-container>
