import { Component, ElementRef, Optional, ViewChild } from '@angular/core';
import { AutocompleteEnum } from '@inshared/website-ui-components/components/forms/autocomplete.enum';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { twoFactorI18n } from '../two-factor-i18n';

@Component({
    selector: 'ins-two-factor-input',
    templateUrl: './otp-input.component.html',
    styleUrl: './otp-input.component.scss',
    standalone: true,
    imports: [
        FormsModule,
    ],
})
export class OtpInputComponent implements ControlValueAccessor {
    @ViewChild('input') inputElement: ElementRef;
    protected readonly AutocompleteEnum = AutocompleteEnum;

    public isDisabled: boolean = false;
    public value = '';
    public oldValue = '';
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    hasFocus = false;

    constructor(@Optional() public ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    onPaste(event: ClipboardEvent): void {
        event.preventDefault();
        const pasteData = event.clipboardData.getData('text');
        if (pasteData) {
            this.value = pasteData.replace(/\D/g, '');
            this.onModelChange();
        }
    }

    onModelChange() {
        if (this.value !== this.oldValue) {
            this.oldValue = this.value;
            this.onChange(this.value);
        }
    }

    onKeyDown(event: KeyboardEvent): boolean {
        // Allow numeric keys, Backspace, and Ctrl+V
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
        const isCtrlV = event.ctrlKey && event.key === 'v';

        return allowedKeys.includes(event.key) || isCtrlV;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: string): void {
        this.value = value || '';
    }

    handleFocus(hasFocus: boolean) {
        this.hasFocus = hasFocus;
        this.setCursorAtEnd();
    }

    setCursorAtEnd() {
        const input = this.inputElement.nativeElement;
        const length = input.value.length;
        input.setSelectionRange(length, length);
    }

    setFocus() {
        const input = this.inputElement.nativeElement as HTMLInputElement;
        input.focus();
    }

    protected readonly twoFactorI18n = twoFactorI18n;
}
