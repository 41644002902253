import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import {
    ApiDownloadInvoiceResponse,
    ApiGetInvoiceDetailsInterface,
    ApiGetInvoiceDetailsPayloadInterface,
    ApiGetInvoicesInterface,
    ApiGetInvoicesPayloadInterface,
    DownloadInvoiceInterface,
    GetInvoiceDetailsInterface,
    GetInvoiceDetailsPayloadInterface,
    GetInvoicesInterface,
    GetInvoicesPayloadInterface,
} from '../interfaces';
import {
    downloadInvoiceRequestMapper,
    downloadInvoiceResponseMapper,
    getInvoiceDetailsMapper,
    mapInvoicesResponse,
} from '../mappers';

const RESOURCES = {
    GET_INVOICES: 'en-gb/relation-financial/request/total-invoices',
    GET_INVOICE_DETAILS: 'en-gb/relation-financial/request/determination-invoice',
    DOWNLOAD_INVOICE: 'en-gb/relation-financial/provide/pdf-invoice',
};

@Injectable({
    providedIn: 'root',
})
export class InvoiceApiService {
    public constructor(private apiService: ApiService) {}

    public getInvoices$(payload?: GetInvoicesPayloadInterface): Observable<GetInvoicesInterface> {
        const request: ApiGetInvoicesPayloadInterface | object = payload ? {
            date_year_part: payload.year,
        } : {};

        return this.apiService
            .post$<ApiGetInvoicesInterface>(RESOURCES.GET_INVOICES, request)
            .pipe(map(mapInvoicesResponse));
    }

    public getInvoiceDetails$(payload: GetInvoiceDetailsPayloadInterface): Observable<GetInvoiceDetailsInterface> {
        const request: ApiGetInvoiceDetailsPayloadInterface = {
            invoice_id: payload.invoiceId,
        };

        return this.apiService
            .post$<ApiGetInvoiceDetailsInterface>(RESOURCES.GET_INVOICE_DETAILS, request)
            .pipe(map(getInvoiceDetailsMapper));
    }

    public downloadInvoice$(invoiceId: string): Observable<DownloadInvoiceInterface> {
        return this.apiService
            .post$<ApiDownloadInvoiceResponse>(RESOURCES.DOWNLOAD_INVOICE, downloadInvoiceRequestMapper(invoiceId))
            .pipe(map(downloadInvoiceResponseMapper));
    }
}
