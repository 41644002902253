import { inject, Injectable } from '@angular/core';
import { AuthenticationActionService, AuthenticationDataService, ErrorInterface } from 'outshared-lib';
import { AuthenticationApiService } from 'outshared-lib/authentication/services/authentication.api.service';
import { catchError, map, Observable, of, take } from 'rxjs';
import { TwoFactorDataInterface } from './two-factor-data.interface';
import { TwoFactorMethodEnum } from './two-factor-method.enum';

@Injectable()
export class TwoFactorService {
    #authenticationApiService = inject(AuthenticationApiService);
    #authenticationActionService = inject(AuthenticationActionService);
    #authenticationDataService = inject(AuthenticationDataService);

    get data$(): Observable<TwoFactorDataInterface> {
        return this.#authenticationDataService.getState$().pipe(
            take(1),
            map(
                (state) =>
                    ({
                        relationId: state.relationId,
                        phoneNumber: state.phonePreview,
                        email: '',
                        generalMessage: state.generalMessage,
                        sessionExpireDate: new Date(state.sessionStart.getTime() + state.sessionDurationInSeconds * 1000)
                    }) as TwoFactorDataInterface,
            ),
        );
    }

    requestCode(relationId: number, method: TwoFactorMethodEnum): Observable<ErrorInterface[]> {
        return this.#authenticationApiService.request2faCode({ relationId, method }).pipe(
            map(() => []),
            catchError((errors: ErrorInterface[]) => of(errors)),
        );
    }

    validateCode(relationId: number, otp: string): Observable<ErrorInterface[]> {
        return this.#authenticationActionService.validateTwoFactorAuthentication$(relationId, otp).pipe(
            map(() => []),
            catchError((errors: ErrorInterface[]) => of(errors)),
        );
    }
}
