import { AuthenticationStatusEnum } from '../enums';
import { CredentialsInterface } from '../interfaces';

export interface Authentication {
    authentication: AuthenticationState;
}

export interface AuthenticationState {
    initializationPending: boolean;
    loginPending: boolean;
    logoutPending: boolean;
    sessionDurationInSeconds?: number;
    sessionStart?: Date;
    status: AuthenticationStatusEnum;
    token?: string;
    credentials?: CredentialsInterface;
    relationId?: number;
    phonePreview?: string;
    generalMessage?: string;
}

export const INITIAL_AUTHENTICATION_STATE: AuthenticationState = {
    initializationPending: false,
    loginPending: false,
    logoutPending: false,
    sessionDurationInSeconds: null,
    sessionStart: null,
    status: AuthenticationStatusEnum.LoggedOut,
    token: null,
    credentials: null,
};

export const AUTHENTICATION_FEATURE = 'authentication';
