export const twoFactorI18n = {
    // SMS/email selection form
    askSmsOrMailTitle: $localize`:@@spa.two-factor.ask-sms-or-mail-title:How would you like to receive the verification code?`,
    smsButton: $localize`:@@spa.two-factor.sms-button:SMS`,
    mailButton: $localize`:@@spa.two-factor.mail-button:E-mail`,

    // Verification form
    enterCodeTitle: $localize`:@@spa.two-factor.enter-code-title:Enter your verification code`,
    smsConformationText: $localize`:@@spa.two-factor.sms-conformation-text:Your verification code has been sent to your phone number. This code will expire within 10 minutes.`,
    mailConformationText: $localize`:@@spa.two-factor.mail-conformation-text:Your verification code has been sent to your email. This code will expire within 10 minutes.`,

    // resend section open link
    smsNotRecievedButton: $localize`:@@spa.two-factor.sms-not-recieved-button:Didn't receive an SMS?`,
    mailNotRecievedButton: $localize`:@@spa.two-factor.mail-not-recieved-button:Didn't receive an email?`,
    codeNotRecievedButton: $localize`:@@spa.two-factor.code-not-recieved-button:Didn't receive a code?`,

    // resend title
    smsNotRecievedTitle: $localize`:@@spa.two-factor.sms-not-recieved-title:Did you not receive an SMS?`,
    mailNotRecievedTitle: $localize`:@@spa.two-factor.mail-not-recieved-title:Did you not receive an email?`,
    codeNotRecievedTitle: $localize`:@@spa.two-factor.code-not-recieved-title:Did you not receive a code?`,

    // resend text
    smsNotRecievedText: $localize`:@@spa.two-factor.sms-not-recieved-text:Check if your number ends in: #`,
    mailNotRecievedText: $localize`:@@spa.two-factor.mail-not-recieved-text:Check your spam|Check your email address: #`,
    codeNotRecievedText: $localize`:@@spa.two-factor.code-not-recieved-text:Choose how you want to receive a new code:`,

    // resend button variations
    resendBySmsButton: $localize`:@@spa.two-factor.resend-by-sms-button:Resend SMS`,
    resendByMailButton: $localize`:@@spa.two-factor.resend-by-mail-button:Resend email`,
    orSendBySmsButton: $localize`:@@spa.two-factor.or-send-by-sms-button:Or send code by SMS`,
    orSendByMailButton: $localize`:@@spa.two-factor.or-send-by-mail-button:Or send code by email`,

    // validating
    validating: $localize`:@@spa.two-factor.validating:Login`,
}
