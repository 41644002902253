<div class="otp">
    <div class="digits" [class.has-focus]="hasFocus">
        @for (char of [].constructor(6); track char) {
            <div
                class="digit"
                [class.selected]="$index === value.length"
                [class.disabled]="isDisabled"
            >
                @if ($index >= value.length) {
                    &nbsp;
                } @else {
                    {{ value[$index] }}
                }
                <div class="cursor"></div>
            </div>
        }
    </div>
    <div class="otp-input">
        <input
            #input
            autofocus
            type="text"
            [autocomplete]="AutocompleteEnum.OneTimeCode"
            inputmode="numeric"
            maxlength="6"
            pattern="\d{6}"
            [title]="twoFactorI18n.enterCodeTitle"
            [(ngModel)]="value"
            (ngModelChange)="onModelChange()"
            (focusin)="handleFocus(true)"
            (focusout)="handleFocus(false)"
            (paste)="onPaste($event)"
            (keydown)="onKeyDown($event)"
            [disabled]="isDisabled"
        >
    </div>
</div>
