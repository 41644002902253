import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorInterface, LoginRequestInterface } from 'outshared-lib';
import { AccountRecoveryModalRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
    public get email(): AbstractControl {
        return this.form.get('email');
    }

    public get password(): AbstractControl {
        return this.form.get('password');
    }

    @Input() public loading: boolean;
    @Input() public errors: ErrorInterface[] = [];

    @Output() public readonly login: EventEmitter<LoginRequestInterface> = new EventEmitter();

    public form: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', {
            validators: [Validators.required, Validators.email],
        }),
        password: new UntypedFormControl('', {
            validators: [Validators.required],
        }),
        stayLoggedIn: new UntypedFormControl(false),
    });

    public accountRecoveryModalLink = ['', { outlets: { modal: AccountRecoveryModalRoutesEnum.AccountRecovery } }];

    constructor(@Inject(DOCUMENT) private document: Document) {}

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.login.emit(this.form.value);
    }

    /**
     *  Chrome autofill bugfix (https://github.com/angular/components/issues/3414)
     *  If Chrome uses autofill to set username and password,
     *  password is not set directly (only after user interaction).
     *  The emailChanged() function sets password if email is set which fixes the
     *  Chrome autofill bug.
     */
    public emailChanged(event: Event): void {
        const email = event.target as HTMLInputElement;
        const password = this.document.getElementById('password') as HTMLInputElement;

        if (email.value) {
            this.email.setValue(email.value);
        }
        if (password.value) {
            this.password.setValue(password.value);
        }
    }
}
